export const environment = {
    production: true,
    recaptcha: {
        // siteKey: '6LcmT6QhAAAAAA-HvCWkRMydP9WSBpnEWF8QM460'
        siteKey: '6LcSaaMhAAAAACyA148w9tEB6uH8cnNbwJsXJkDF'
    },
    keyCloackUrl: 'https://login.lexnow-labs.com/realms/lexnow-labs-sso/protocol/openid-connect/auth',
    keyCloackClientId: 'lexnow-labs',
    frontDomain: 'https://app.lexnow-labs.com'
};
